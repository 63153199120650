import React, { useState } from "react";
import { Container, Navbar, Image, Button, Dropdown } from "react-bootstrap";
import VerbConjugationPage from "./components/VerbConjugationPage";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import logo from "./logo.png";
import ExerciseCommentCode from "./components/ExerciseCommentCode";

function App() {
  const [showExpressionPanel, setShowExpressionPanel] = useState(false);
  const [showExpressionPanelConjugator, setShowExpressionPanelConjugator] =
    useState(true);
  const [showExercise, setShowExercise] = useState(false);

  const toggleExerciseWeb = () => {
    setShowExercise(!showExercise);
  };

  const toggleExercise = () => {
    setShowExercise(true);
    setShowExpressionPanel(false);
    setShowExpressionPanelConjugator(false);
  };

  const toggleExpressionPanel = () => {
    setShowExpressionPanel(true);
    setShowExercise(false);
    setShowExpressionPanelConjugator(false);
  };

  const toggleExpressionPanelConjugator = () => {
    setShowExpressionPanelConjugator(true);
    setShowExpressionPanel(true);
    setShowExercise(true);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const bodyStyle = {
    overflowX: "hidden",
    overflowY: "hidden",
  };

  return (
    <div style={bodyStyle}>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
            <Image
              src="https://cdn-icons-png.flaticon.com/128/197/197374.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Logo"
            />{" "}
            getstudymore.com.br
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {isMobileDevice() ? (
              <Dropdown drop="start">
                <Dropdown.Toggle variant="outline-light">
                  Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={toggleExercise}>
                    Code analysis
                  </Dropdown.Item>
                  <Dropdown.Item onClick={toggleExpressionPanel}>
                    Improve my expression
                  </Dropdown.Item>
                  <Dropdown.Item onClick={toggleExpressionPanelConjugator}>
                    Powerful conjugator
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <>
                <Button
                  variant="outline-light"
                  onClick={toggleExerciseWeb}
                  className="me-2"
                >
                  {showExercise ? "Powerful conjugator" : "Code analysis"}
                </Button>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        {!isMobileDevice() && (
          <>
            {!showExercise && <VerbConjugationPage />}
            {showExercise && <ExerciseCommentCode />}
          </>
        )}
        {/* <stripe-pricing-table
          pricing-table-id="prctbl_1PxmnHGkO49MG6xztb0PpODh"
          publishable-key="pk_live_51Ns0HeGkO49MG6xz6VzEoVbGAqejNxYsLkU9A0XAuCW5joqGKplwG8dmLCZZBm0Pf7anfIW61O5AATPcacOvtX6Z00uDsUz9an"
        ></stripe-pricing-table> */}
      </Container>
      {isMobileDevice() && (
        <Container className="mt-4">
          {showExpressionPanelConjugator && <VerbConjugationPage />}
          {showExpressionPanel && !showExercise && (
            <div
              className="d-flex flex-column p-1"
              style={{ minWidth: "280px", maxWidth: "500px" }}
            >
              <header className="p-1 d-flex flex-column gap-2 text-center mb-1">
                <h5 className="fw-bold">
                  Learn how to improve your expression
                </h5>
              </header>
              <div className="overflow-div">
                <Vocabulary />
              </div>
            </div>
          )}
          {showExercise &&
            !showExpressionPanelConjugator &&
            !showExpressionPanel && <ExerciseCommentCode />}
        </Container>
      )}
    </div>
  );
}

function Vocabulary() {
  const [subjectInput, setSubjectInput] = useState("");
  const [responseText, setResponseText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGenerate = async () => {
    setLoading(true);
    const promptText = `Please correct my phrase: ${subjectInput}`;
    const url =
      "https://xqpa24frhml6em7ceyqy5zxzvm0evqdd.lambda-url.us-east-2.on.aws/generate";

    try {
      const response = await axios.get(url, {
        params: {
          prompt: promptText,
        },
      });

      setResponseText(response.data.response);
      setError("");

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to generate text. Please try again.");
      setResponseText("");
      setLoading(false);
    }
  };

  const onClickAdd = (e) => {
    e.preventDefault();
    handleGenerate();
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className="row justify-content-center">
        <div style={{ width: "200px" }}>
          <label style={{ fontSize: "12px", display: "block" }}>
            Expression:
          </label>
          <input
            className="rounded prompt"
            type="text"
            name="subjectInput"
            value={subjectInput}
            onChange={(e) => setSubjectInput(e.target.value)}
            style={{
              backgroundColor: "#0000",
              fontSize: "11px",
              width: window.innerWidth < 1600 ? "200px" : "200px",
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-center">
          <button className="prompt-add-btn" onClick={onClickAdd}>
            {loading && <Spinner />}
            My expression is correct?
            <i className="bi bi-magic" style={{ marginLeft: "8px" }}></i>
          </button>
        </div>
      </div>
      <div className="mt-4">
        {responseText && (
          <div className="cardVocabulary">
            <div className="cardVocabulary-body">
              <div className="examples-list">
                {responseText.split("\n").map((example, index) => (
                  <p
                    key={index}
                    className="example-item"
                    dangerouslySetInnerHTML={{
                      __html: example.replace(
                        /\*\*(.*?)\*\*/g,
                        '<span class="highlight">$1</span>'
                      ),
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {error && <p className="text-danger mt-3">{error}</p>}
      </div>
    </div>
  );
}

export default App;
