import React, { useState, useEffect } from "react";
import { analytics } from "./firebaseConfig";
import { logEvent } from "firebase/analytics";
import "./styles.css";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import imageLogo from "./logo.png";

const TITLE = "Powerful English Conjugator";

const SUBTITLE1 = `Dedicated to students and professionals who wants to achieve useful English vocabulary in IT`;
//const SUBTITLE1_IMAGE_TO_VIDEO = `Your first prompt should be a short description of what you see in the image you uploaded.`;
const SUBTITLE2 = `Explore how to use specific verbs on all different verbal forms`;
const SUBTITLE2_MOBILE = `Explore how to use specific verbs on all different verbal forms`;
const DESCRIPTION = `Select a specific subarea that you are inside that and select the verbal form that you can learn about`;

const TIP = `Put the verb that you can learn and improve your knowledge about. Example "to develop"`;
const ADD = `Conjugate`;

export default function VerbConjugationPage() {
  useEffect(() => {
    logEvent(analytics, "homepage_view");
  }, []);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div className="ps rounded m-3 d-flex flex-grow-1 align-items-stretch justify-content-center">
      {/* MAIN */}
      <div className="d-flex flex-column flex-grow-1 align-items-stretch border-end">
        <header className="p-3 d-flex flex-column gap-2 text-center mb-4">
          <div className="justify-content-center">
            <img
              src={imageLogo}
              style={{ height: "130px", width: "130px", borderRadius: "20px" }}
            />
          </div>
          <h1 className="fw-bold">{TITLE}</h1>
          <div className="d-flex flex-column">
            <p className="text-muted p-0 m-0">{SUBTITLE1}</p>
            {!isMobileDevice() && (
              <p className="text-muted p-0 m-0">{SUBTITLE2}</p>
            )}
          </div>
        </header>
        <div className="d-flex flex-grow-1">
          <div className="overflow-div flex-grow-1">
            <Conjugation />
          </div>
        </div>
      </div>

      {!isMobileDevice() && (
        <div
          className="d-flex flex-column p-1"
          style={{ minWidth: "280px", maxWidth: "500px" }}
        >
          <header className="p-1 d-flex flex-column gap-2 text-center mb-1">
            <h5 className="fw-bold">Learn how to improve your expression</h5>
          </header>
          <div className="overflow-div">
            <Vocabulary />
          </div>
        </div>
      )}
    </div>
  );
}

function Conjugation() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div className="p-4 overflow-auto">
      <div className="d-flex flex-column">
        {!isMobileDevice() && (
          <p style={{ textAlign: "center" }}>{DESCRIPTION}</p>
        )}

        <strong style={{ textAlign: "center" }}>{TIP}</strong>

        {/* {isMobileDevice() && (
          <p style={{ textAlign: "center" }}>{SUBTITLE2_MOBILE}</p>
        )} */}
      </div>
      {isMobileDevice && (
        <div className="d-flex flex-column mt-5">
          <Verb />
        </div>
      )}
    </div>
  );
}

function Verb(props) {
  const [areaProfesional, setAreaProfesional] = useState("");
  const [verbalForm, setVerbalForm] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [responseText, setResponseText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGenerate = async () => {
    setLoading(true);
    const promptText = `Please provide examples using the verb "${subjectInput}" in "${verbalForm}" within the context of "${areaProfesional}". Format your examples as bullet points or short phrases`;
    const url =
      "https://xqpa24frhml6em7ceyqy5zxzvm0evqdd.lambda-url.us-east-2.on.aws/generate";

    try {
      const response = await axios.get(url, {
        params: {
          prompt: promptText,
        },
      });

      setResponseText(response.data.response);
      setError("");

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to generate text. Please try again."); // Set error message for UI
      setResponseText(""); // Clear previous response on error

      setLoading(false);
    }
  };

  const onClickAdd = (e) => {
    e.preventDefault();
    handleGenerate();
  };

  useEffect(() => {
    const meta = document.createElement("meta");
    meta.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    );
    meta.setAttribute("name", "viewport");
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const handleWordClick = (subjectInput) => {
    window.open(
      `https://dictionary.cambridge.org/dictionary/english/${encodeURIComponent(
        subjectInput
      )}`,
      "_blank"
    );
  };

  const isTypeVerb = subjectInput === "verb";

  const renderExamples = () => {
    return responseText.split("\n").map((example, index) => {
      // Replace **text** with <span class="highlight">text</span>
      const formattedExample = example.replace(
        /\*\*(.*?)\*\*/g,
        '<span class="highlight">$1</span>'
      );

      // Use dangerouslySetInnerHTML to render HTML inside React
      return (
        <p
          key={index}
          className="example-item"
          dangerouslySetInnerHTML={{ __html: formattedExample }}
          onClick={() => handleWordClick(subjectInput)}
          style={{ cursor: "pointer" }}
          title="Click to learn more on Cambridge dictionary"
        />
      );
    });
  };

  return (
    <div className="d-flex flex-column gap-3">
      {isMobileDevice ? (
        <div className="row">
          <div className="col-2" style={{ width: "250px" }}>
            <label
              style={{
                fontSize: "12px",
                marginBottom: "5px",
                display: "block",
              }}
            >
              Profesional Area:
            </label>
            <select
              style={{ fontSize: "11px" }}
              name="areaProfesional"
              className="form-select"
              value={areaProfesional}
              onChange={(e) => setAreaProfesional(e.target.value)}
            >
              <option value="Area Profesional">
                Specific Profesional Area
              </option>
              <option value="Software Development">Software Development</option>
              <option value="Web Development">Web Development</option>
              <option value="Mobile App Development">
                Mobile App Development
              </option>
              <option value="System Architecture">System Architecture</option>
              <option value="Network Administration">
                Network Administration
              </option>
              <option value="Cybersecurity">Cybersecurity</option>
              <option value="Cloud Computing">Cloud Computing</option>
              <option value="DevOps">DevOps</option>
              <option value="Database Management">Database Management</option>
              <option value="Data Science">Data Science</option>
              <option value="Artificial Intelligence">
                Artificial Intelligence
              </option>
              <option value="Machine Learning">Machine Learning</option>
              <option value="Big Data">Big Data</option>
              <option value="IT Support">IT Support</option>
              <option value="IT Consulting">IT Consulting</option>
              <option value="Project Management">Project Management</option>
              <option value="Quality Assurance">Quality Assurance</option>
              <option value="UI/UX Design">UI/UX Design</option>
              <option value="Game Development">Game Development</option>
              <option value="IT Security">IT Security</option>
              <option value="Information Systems Management">
                Information Systems Management
              </option>
              <option value="Systems Integration">Systems Integration</option>
              <option value="IT Governance">IT Governance</option>
              <option value="Enterprise Architecture">
                Enterprise Architecture
              </option>
              <option value="E-commerce">E-commerce</option>
              <option value="Blockchain">Blockchain</option>
              <option value="Virtualization">Virtualization</option>
              <option value="IoT (Internet of Things)">
                IoT (Internet of Things)
              </option>
              <option value="Augmented Reality">Augmented Reality</option>
              <option value="Virtual Reality">Virtual Reality</option>
              <option value="IT Auditing">IT Auditing</option>
              <option value="Business Intelligence">
                Business Intelligence
              </option>
              <option value="Information Technology Infrastructure Library (ITIL)">
                Information Technology Infrastructure Library (ITIL)
              </option>
              <option value="Customer Relationship Management (CRM)">
                Customer Relationship Management (CRM)
              </option>
              <option value="Enterprise Resource Planning (ERP)">
                Enterprise Resource Planning (ERP)
              </option>
              <option value="Computer Forensics">Computer Forensics</option>
              <option value="Health Information Technology">
                Health Information Technology
              </option>
              <option value="Technical Writing">Technical Writing</option>
              <option value="Telecommunications">Telecommunications</option>
              <option value="IT Training">IT Training</option>
            </select>
          </div>

          <div className="col-2" style={{ width: "250px" }}>
            <label
              style={{
                fontSize: "12px",
                marginBottom: "5px",
                display: "block",
              }}
            >
              Verbal Form:
            </label>
            <select
              style={{ fontSize: "11px" }}
              name="verbalForm"
              className="form-select"
              value={verbalForm}
              onChange={(e) => setVerbalForm(e.target.value)}
            >
              <option value="Verbal form">Verbal form</option>
              <option value="Infinitive">Infinitive</option>
              <option value="Present Simple">Present Simple</option>
              <option value="Present Continuous">Present Continuous</option>
              <option value="Past Simple">Past Simple</option>
              <option value="Past Continuous">Past Continuous</option>
              <option value="Present Perfect">Present Perfect</option>
              <option value="Past Perfect">Past Perfect</option>
              <option value="Future Simple">Future Simple</option>
              <option value="Future Continuous">Future Continuous</option>
              <option value="Future Perfect">Future Perfect</option>
              <option value="Conditional">Conditional</option>
              <option value="Subjunctive">Subjunctive</option>
              <option value="Imperative">Imperative</option>
              <option value="Gerund">Gerund</option>
              <option value="Participle">Participle</option>
              <option value="Modal verbs">Modal verbs</option>
              <option value="Phrasal verbs">Phrasal verbs</option>
              <option value="Irregular verbs">Irregular verbs</option>
            </select>
          </div>

          <div
            className="col-3"
            style={{ width: isMobileDevice() ? "250px" : "200px" }}
          >
            <label
              style={{
                fontSize: "12px",
                marginBottom: "5px",
                display: "block",
              }}
            >
              Verb:
            </label>
            <select
              style={{ fontSize: "11px" }}
              name="subjectInput"
              className="form-select"
              value={subjectInput}
              onChange={(e) => setSubjectInput(e.target.value)}
            >
              <option value="verb">Choose the verb</option>
              <option value="to develop">to develop</option>
              <option value="to code">to code</option>
              <option value="to program">to program</option>
              <option value="to debug">to debug</option>
              <option value="to compile">to compile</option>
              <option value="to execute">to execute</option>
              <option value="to deploy">to deploy</option>
              <option value="to test">to test</option>
              <option value="to automate">to automate</option>
              <option value="to configure">to configure</option>
              <option value="to integrate">to integrate</option>
              <option value="to design">to design</option>
              <option value="to architect">to architect</option>
              <option value="to secure">to secure</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to optimize">to optimize</option>
              <option value="to implement">to implement</option>
              <option value="to analyze">to analyze</option>
              <option value="to monitor">to monitor</option>
              <option value="to manage">to manage</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to upgrade">to upgrade</option>
              <option value="to migrate">to migrate</option>
              <option value="to patch">to patch</option>
              <option value="to troubleshoot">to troubleshoot</option>
              <option value="to virtualize">to virtualize</option>
              <option value="to streamline">to streamline</option>
              <option value="to benchmark">to benchmark</option>
              <option value="to document">to document</option>
              <option value="to install">to install</option>
              <option value="to uninstall">to uninstall</option>
              <option value="to scan">to scan</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to query">to query</option>
              <option value="to index">to index</option>
              <option value="to retrieve">to retrieve</option>
              <option value="to store">to store</option>
              <option value="to access">to access</option>
              <option value="to sync">to sync</option>
              <option value="to share">to share</option>
              <option value="to upload">to upload</option>
              <option value="to download">to download</option>
              <option value="to stream">to stream</option>
              <option value="to host">to host</option>
              <option value="to mirror">to mirror</option>
              <option value="to replicate">to replicate</option>
              <option value="to scale">to scale</option>
              <option value="to load">to load</option>
              <option value="to balance">to balance</option>
              <option value="to cache">to cache</option>
              <option value="to validate">to validate</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to parse">to parse</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to initialize">to initialize</option>
              <option value="to instantiate">to instantiate</option>
              <option value="to render">to render</option>
              <option value="to animate">to animate</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to build">to build</option>
              <option value="to generate">to generate</option>
              <option value="to rollback">to rollback</option>
              <option value="to refactor">to refactor</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to validate">to validate</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to parse">to parse</option>
              <option value="to compile">to compile</option>
              <option value="to execute">to execute</option>
              <option value="to commit">to commit</option>
              <option value="to merge">to merge</option>
              <option value="to fork">to fork</option>
              <option value="to branch">to branch</option>
              <option value="to clone">to clone</option>
              <option value="to push">to push</option>
              <option value="to pull">to pull</option>
              <option value="to fetch">to fetch</option>
              <option value="to rollback">to rollback</option>
              <option value="to rebase">to rebase</option>
              <option value="to diff">to diff</option>
              <option value="to log">to log</option>
              <option value="to annotate">to annotate</option>
              <option value="to blame">to blame</option>
              <option value="to cherry-pick">to cherry-pick</option>
              <option value="to stash">to stash</option>
              <option value="to pop">to pop</option>
              <option value="to apply">to apply</option>
              <option value="to drop">to drop</option>
              <option value="to clean">to clean</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to bundle">to bundle</option>
              <option value="to unbundle">to unbundle</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to join">to join</option>
              <option value="to union">to union</option>
              <option value="to intersect">to intersect</option>
              <option value="to except">to except</option>
              <option value="to select">to select</option>
              <option value="to project">to project</option>
              <option value="to group">to group</option>
              <option value="to pivot">to pivot</option>
              <option value="to unpivot">to unpivot</option>
              <option value="to rank">to rank</option>
              <option value="to dense-rank">to dense-rank</option>
              <option value="to row-number">to row-number</option>
              <option value="to cume-dist">to cume-dist</option>
              <option value="to percent-rank">to percent-rank</option>
              <option value="to ntile">to ntile</option>
              <option value="to lag">to lag</option>
              <option value="to lead">to lead</option>
              <option value="to first-value">to first-value</option>
              <option value="to last-value">to last-value</option>
              <option value="to nth-value">to nth-value</option>
              <option value="to generate">to generate</option>
              <option value="to summarize">to summarize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to compute">to compute</option>
              <option value="to calculate">to calculate</option>
              <option value="to evaluate">to evaluate</option>
              <option value="to estimate">to estimate</option>
              <option value="to approximate">to approximate</option>
              <option value="to predict">to predict</option>
              <option value="to forecast">to forecast</option>
              <option value="to model">to model</option>
              <option value="to train">to train</option>
              <option value="to test">to test</option>
              <option value="to validate">to validate</option>
              <option value="to tune">to tune</option>
              <option value="to optimize">to optimize</option>
              <option value="to regularize">to regularize</option>
              <option value="to dropout">to dropout</option>
              <option value="to batch">to batch</option>
              <option value="to normalize">to normalize</option>
              <option value="to standardize">to standardize</option>
              <option value="to transform">to transform</option>
              <option value="to scale">to scale</option>
              <option value="to resample">to resample</option>
              <option value="to impute">to impute</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to embed">to embed</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to stem">to stem</option>
              <option value="to lemmatize">to lemmatize</option>
              <option value="to annotate">to annotate</option>
              <option value="to label">to label</option>
              <option value="to tag">to tag</option>
              <option value="to classify">to classify</option>
              <option value="to cluster">to cluster</option>
              <option value="to segment">to segment</option>
              <option value="to detect">to detect</option>
              <option value="to recognize">to recognize</option>
              <option value="to identify">to identify</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to obfuscate">to obfuscate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to balance">to balance</option>
              <option value="to replicate">to replicate</option>
              <option value="to sync">to sync</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to parse">to parse</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to validate">to validate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to transform">to transform</option>
              <option value="to map">to map</option>
              <option value="to reduce">to reduce</option>
              <option value="to fold">to fold</option>
              <option value="to filter">to filter</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to buffer">to buffer</option>
              <option value="to queue">to queue</option>
              <option value="to dequeue">to dequeue</option>
              <option value="to poll">to poll</option>
              <option value="to stream">to stream</option>
              <option value="to batch">to batch</option>
              <option value="to log">to log</option>
              <option value="to audit">to audit</option>
              <option value="to track">to track</option>
              <option value="to profile">to profile</option>
              <option value="to analyze">to analyze</option>
              <option value="to visualize">to visualize</option>
              <option value="to graph">to graph</option>
              <option value="to chart">to chart</option>
              <option value="to diagram">to diagram</option>
              <option value="to model">to model</option>
              <option value="to simulate">to simulate</option>
              <option value="to emulate">to emulate</option>
              <option value="to test">to test</option>
              <option value="to monitor">to monitor</option>
              <option value="to alert">to alert</option>
              <option value="to notify">to notify</option>
              <option value="to report">to report</option>
              <option value="to debug">to debug</option>
              <option value="to trace">to trace</option>
              <option value="to diagnose">to diagnose</option>
              <option value="to troubleshoot">to troubleshoot</option>
              <option value="to fix">to fix</option>
              <option value="to repair">to repair</option>
              <option value="to resolve">to resolve</option>
              <option value="to escalate">to escalate</option>
              <option value="to automate">to automate</option>
              <option value="to script">to script</option>
              <option value="to schedule">to schedule</option>
              <option value="to orchestrate">to orchestrate</option>
              <option value="to provision">to provision</option>
              <option value="to manage">to manage</option>
              <option value="to administer">to administer</option>
              <option value="to operate">to operate</option>
              <option value="to maintain">to maintain</option>
              <option value="to support">to support</option>
              <option value="to deploy">to deploy</option>
              <option value="to release">to release</option>
              <option value="to version">to version</option>
              <option value="to upgrade">to upgrade</option>
              <option value="to patch">to patch</option>
              <option value="to hotfix">to hotfix</option>
              <option value="to rollback">to rollback</option>
              <option value="to clone">to clone</option>
              <option value="to fork">to fork</option>
              <option value="to merge">to merge</option>
              <option value="to commit">to commit</option>
              <option value="to push">to push</option>
              <option value="to pull">to pull</option>
              <option value="to fetch">to fetch</option>
              <option value="to branch">to branch</option>
              <option value="to tag">to tag</option>
              <option value="to stage">to stage</option>
              <option value="to unstage">to unstage</option>
              <option value="to reset">to reset</option>
              <option value="to rebase">to rebase</option>
              <option value="to diff">to diff</option>
              <option value="to log">to log</option>
              <option value="to annotate">to annotate</option>
              <option value="to blame">to blame</option>
              <option value="to cherry-pick">to cherry-pick</option>
              <option value="to stash">to stash</option>
              <option value="to pop">to pop</option>
              <option value="to apply">to apply</option>
              <option value="to drop">to drop</option>
              <option value="to clean">to clean</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to bundle">to bundle</option>
              <option value="to unbundle">to unbundle</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to join">to join</option>
              <option value="to union">to union</option>
              <option value="to intersect">to intersect</option>
              <option value="to except">to except</option>
              <option value="to select">to select</option>
              <option value="to project">to project</option>
              <option value="to group">to group</option>
              <option value="to pivot">to pivot</option>
              <option value="to unpivot">to unpivot</option>
              <option value="to rank">to rank</option>
              <option value="to dense-rank">to dense-rank</option>
              <option value="to row-number">to row-number</option>
              <option value="to cume-dist">to cume-dist</option>
              <option value="to percent-rank">to percent-rank</option>
              <option value="to ntile">to ntile</option>
              <option value="to lag">to lag</option>
              <option value="to lead">to lead</option>
              <option value="to first-value">to first-value</option>
              <option value="to last-value">to last-value</option>
              <option value="to nth-value">to nth-value</option>
              <option value="to generate">to generate</option>
              <option value="to summarize">to summarize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to compute">to compute</option>
              <option value="to calculate">to calculate</option>
              <option value="to evaluate">to evaluate</option>
              <option value="to estimate">to estimate</option>
              <option value="to approximate">to approximate</option>
              <option value="to predict">to predict</option>
              <option value="to forecast">to forecast</option>
              <option value="to model">to model</option>
              <option value="to train">to train</option>
              <option value="to test">to test</option>
              <option value="to validate">to validate</option>
              <option value="to tune">to tune</option>
              <option value="to optimize">to optimize</option>
              <option value="to regularize">to regularize</option>
              <option value="to dropout">to dropout</option>
              <option value="to batch">to batch</option>
              <option value="to normalize">to normalize</option>
              <option value="to standardize">to standardize</option>
              <option value="to transform">to transform</option>
              <option value="to scale">to scale</option>
              <option value="to resample">to resample</option>
              <option value="to impute">to impute</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to embed">to embed</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to stem">to stem</option>
              <option value="to lemmatize">to lemmatize</option>
              <option value="to annotate">to annotate</option>
              <option value="to label">to label</option>
              <option value="to tag">to tag</option>
              <option value="to classify">to classify</option>
              <option value="to cluster">to cluster</option>
              <option value="to segment">to segment</option>
              <option value="to detect">to detect</option>
              <option value="to recognize">to recognize</option>
              <option value="to identify">to identify</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to obfuscate">to obfuscate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to balance">to balance</option>
              <option value="to replicate">to replicate</option>
              <option value="to sync">to sync</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to parse">to parse</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to validate">to validate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to transform">to transform</option>
              <option value="to map">to map</option>
              <option value="to reduce">to reduce</option>
              <option value="to fold">to fold</option>
              <option value="to filter">to filter</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to buffer">to buffer</option>
              <option value="to queue">to queue</option>
              <option value="to dequeue">to dequeue</option>
              <option value="to poll">to poll</option>
              <option value="to stream">to stream</option>
              <option value="to batch">to batch</option>
              <option value="to log">to log</option>
              <option value="to audit">to audit</option>
              <option value="to track">to track</option>
              <option value="to profile">to profile</option>
              <option value="to analyze">to analyze</option>
              <option value="to visualize">to visualize</option>
              <option value="to graph">to graph</option>
              <option value="to chart">to chart</option>
              <option value="to diagram">to diagram</option>
              <option value="to model">to model</option>
              <option value="to simulate">to simulate</option>
              <option value="to emulate">to emulate</option>
              <option value="to test">to test</option>
              <option value="to monitor">to monitor</option>
              <option value="to alert">to alert</option>
              <option value="to notify">to notify</option>
              <option value="to report">to report</option>
              <option value="to debug">to debug</option>
              <option value="to trace">to trace</option>
              <option value="to diagnose">to diagnose</option>
              <option value="to troubleshoot">to troubleshoot</option>
              <option value="to fix">to fix</option>
              <option value="to repair">to repair</option>
              <option value="to resolve">to resolve</option>
              <option value="to escalate">to escalate</option>
              <option value="to automate">to automate</option>
              <option value="to script">to script</option>
              <option value="to schedule">to schedule</option>
              <option value="to orchestrate">to orchestrate</option>
              <option value="to provision">to provision</option>
              <option value="to manage">to manage</option>
              <option value="to administer">to administer</option>
              <option value="to operate">to operate</option>
              <option value="to maintain">to maintain</option>
              <option value="to support">to support</option>
              <option value="to deploy">to deploy</option>
              <option value="to release">to release</option>
              <option value="to version">to version</option>
              <option value="to upgrade">to upgrade</option>
              <option value="to patch">to patch</option>
              <option value="to hotfix">to hotfix</option>
              <option value="to rollback">to rollback</option>
              <option value="to clone">to clone</option>
              <option value="to fork">to fork</option>
              <option value="to merge">to merge</option>
              <option value="to commit">to commit</option>
              <option value="to push">to push</option>
              <option value="to pull">to pull</option>
              <option value="to fetch">to fetch</option>
              <option value="to branch">to branch</option>
              <option value="to tag">to tag</option>
              <option value="to stage">to stage</option>
              <option value="to unstage">to unstage</option>
              <option value="to reset">to reset</option>
              <option value="to rebase">to rebase</option>
              <option value="to diff">to diff</option>
              <option value="to log">to log</option>
              <option value="to annotate">to annotate</option>
              <option value="to blame">to blame</option>
              <option value="to cherry-pick">to cherry-pick</option>
              <option value="to stash">to stash</option>
              <option value="to pop">to pop</option>
              <option value="to apply">to apply</option>
              <option value="to drop">to drop</option>
              <option value="to clean">to clean</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to bundle">to bundle</option>
              <option value="to unbundle">to unbundle</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to join">to join</option>
              <option value="to union">to union</option>
              <option value="to intersect">to intersect</option>
              <option value="to except">to except</option>
              <option value="to select">to select</option>
              <option value="to project">to project</option>
              <option value="to group">to group</option>
              <option value="to pivot">to pivot</option>
              <option value="to unpivot">to unpivot</option>
              <option value="to rank">to rank</option>
              <option value="to dense-rank">to dense-rank</option>
              <option value="to row-number">to row-number</option>
              <option value="to cume-dist">to cume-dist</option>
              <option value="to percent-rank">to percent-rank</option>
              <option value="to ntile">to ntile</option>
              <option value="to lag">to lag</option>
              <option value="to lead">to lead</option>
              <option value="to first-value">to first-value</option>
              <option value="to last-value">to last-value</option>
              <option value="to nth-value">to nth-value</option>
              <option value="to generate">to generate</option>
              <option value="to summarize">to summarize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to compute">to compute</option>
              <option value="to calculate">to calculate</option>
              <option value="to evaluate">to evaluate</option>
              <option value="to estimate">to estimate</option>
              <option value="to approximate">to approximate</option>
              <option value="to predict">to predict</option>
              <option value="to forecast">to forecast</option>
              <option value="to model">to model</option>
              <option value="to train">to train</option>
              <option value="to test">to test</option>
              <option value="to validate">to validate</option>
              <option value="to tune">to tune</option>
              <option value="to optimize">to optimize</option>
              <option value="to regularize">to regularize</option>
              <option value="to dropout">to dropout</option>
              <option value="to batch">to batch</option>
              <option value="to normalize">to normalize</option>
              <option value="to standardize">to standardize</option>
              <option value="to transform">to transform</option>
              <option value="to scale">to scale</option>
              <option value="to resample">to resample</option>
              <option value="to impute">to impute</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to embed">to embed</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to stem">to stem</option>
              <option value="to lemmatize">to lemmatize</option>
              <option value="to annotate">to annotate</option>
              <option value="to label">to label</option>
              <option value="to tag">to tag</option>
              <option value="to classify">to classify</option>
              <option value="to cluster">to cluster</option>
              <option value="to segment">to segment</option>
              <option value="to detect">to detect</option>
              <option value="to recognize">to recognize</option>
              <option value="to identify">to identify</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to obfuscate">to obfuscate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to balance">to balance</option>
              <option value="to replicate">to replicate</option>
              <option value="to sync">to sync</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to parse">to parse</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to validate">to validate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to transform">to transform</option>
              <option value="to map">to map</option>
              <option value="to reduce">to reduce</option>
              <option value="to fold">to fold</option>
              <option value="to filter">to filter</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to buffer">to buffer</option>
              <option value="to queue">to queue</option>
              <option value="to dequeue">to dequeue</option>
              <option value="to poll">to poll</option>
              <option value="to stream">to stream</option>
              <option value="to batch">to batch</option>
              <option value="to log">to log</option>
              <option value="to audit">to audit</option>
              <option value="to track">to track</option>
              <option value="to profile">to profile</option>
              <option value="to analyze">to analyze</option>
              <option value="to visualize">to visualize</option>
              <option value="to graph">to graph</option>
              <option value="to chart">to chart</option>
              <option value="to diagram">to diagram</option>
              <option value="to model">to model</option>
              <option value="to simulate">to simulate</option>
              <option value="to emulate">to emulate</option>
              <option value="to test">to test</option>
              <option value="to monitor">to monitor</option>
              <option value="to alert">to alert</option>
              <option value="to notify">to notify</option>
              <option value="to report">to report</option>
              <option value="to debug">to debug</option>
              <option value="to trace">to trace</option>
              <option value="to diagnose">to diagnose</option>
              <option value="to troubleshoot">to troubleshoot</option>
              <option value="to fix">to fix</option>
              <option value="to repair">to repair</option>
              <option value="to resolve">to resolve</option>
              <option value="to escalate">to escalate</option>
              <option value="to automate">to automate</option>
              <option value="to script">to script</option>
              <option value="to schedule">to schedule</option>
              <option value="to orchestrate">to orchestrate</option>
              <option value="to provision">to provision</option>
              <option value="to manage">to manage</option>
              <option value="to administer">to administer</option>
              <option value="to operate">to operate</option>
              <option value="to maintain">to maintain</option>
              <option value="to support">to support</option>
              <option value="to deploy">to deploy</option>
              <option value="to release">to release</option>
              <option value="to version">to version</option>
              <option value="to upgrade">to upgrade</option>
              <option value="to patch">to patch</option>
              <option value="to hotfix">to hotfix</option>
              <option value="to rollback">to rollback</option>
              <option value="to clone">to clone</option>
              <option value="to fork">to fork</option>
              <option value="to merge">to merge</option>
              <option value="to commit">to commit</option>
              <option value="to push">to push</option>
              <option value="to pull">to pull</option>
              <option value="to fetch">to fetch</option>
              <option value="to branch">to branch</option>
              <option value="to tag">to tag</option>
              <option value="to stage">to stage</option>
              <option value="to unstage">to unstage</option>
              <option value="to reset">to reset</option>
              <option value="to rebase">to rebase</option>
              <option value="to diff">to diff</option>
              <option value="to log">to log</option>
              <option value="to annotate">to annotate</option>
              <option value="to blame">to blame</option>
              <option value="to cherry-pick">to cherry-pick</option>
              <option value="to stash">to stash</option>
              <option value="to pop">to pop</option>
              <option value="to apply">to apply</option>
              <option value="to drop">to drop</option>
              <option value="to clean">to clean</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to bundle">to bundle</option>
              <option value="to unbundle">to unbundle</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to join">to join</option>
              <option value="to union">to union</option>
              <option value="to intersect">to intersect</option>
              <option value="to except">to except</option>
              <option value="to select">to select</option>
              <option value="to project">to project</option>
              <option value="to group">to group</option>
              <option value="to pivot">to pivot</option>
              <option value="to unpivot">to unpivot</option>
              <option value="to rank">to rank</option>
              <option value="to dense-rank">to dense-rank</option>
              <option value="to row-number">to row-number</option>
              <option value="to cume-dist">to cume-dist</option>
              <option value="to percent-rank">to percent-rank</option>
              <option value="to ntile">to ntile</option>
              <option value="to lag">to lag</option>
              <option value="to lead">to lead</option>
              <option value="to first-value">to first-value</option>
              <option value="to last-value">to last-value</option>
              <option value="to nth-value">to nth-value</option>
              <option value="to generate">to generate</option>
              <option value="to summarize">to summarize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to compute">to compute</option>
              <option value="to calculate">to calculate</option>
              <option value="to evaluate">to evaluate</option>
              <option value="to estimate">to estimate</option>
              <option value="to approximate">to approximate</option>
              <option value="to predict">to predict</option>
              <option value="to forecast">to forecast</option>
              <option value="to model">to model</option>
              <option value="to train">to train</option>
              <option value="to test">to test</option>
              <option value="to validate">to validate</option>
              <option value="to tune">to tune</option>
              <option value="to optimize">to optimize</option>
              <option value="to regularize">to regularize</option>
              <option value="to dropout">to dropout</option>
              <option value="to batch">to batch</option>
              <option value="to normalize">to normalize</option>
              <option value="to standardize">to standardize</option>
              <option value="to transform">to transform</option>
              <option value="to scale">to scale</option>
              <option value="to resample">to resample</option>
              <option value="to impute">to impute</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to embed">to embed</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to stem">to stem</option>
              <option value="to lemmatize">to lemmatize</option>
              <option value="to annotate">to annotate</option>
              <option value="to label">to label</option>
              <option value="to tag">to tag</option>
              <option value="to classify">to classify</option>
              <option value="to cluster">to cluster</option>
              <option value="to segment">to segment</option>
              <option value="to detect">to detect</option>
              <option value="to recognize">to recognize</option>
              <option value="to identify">to identify</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to obfuscate">to obfuscate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to balance">to balance</option>
              <option value="to replicate">to replicate</option>
              <option value="to sync">to sync</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to parse">to parse</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to validate">to validate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to transform">to transform</option>
              <option value="to map">to map</option>
              <option value="to reduce">to reduce</option>
              <option value="to fold">to fold</option>
              <option value="to filter">to filter</option>
            </select>
          </div>

          {/* <div className="col-3">
              <label
                style={{
                  fontSize: "12px",
                  display: "block",
                }}
              >
                Verb:
              </label>
              <input
                className="rounded prompt"
                type="text"
                name="subjectInput"
                value={subjectInput}
                onChange={(e) => setSubjectInput(e.target.value)}
                style={{
                  backgroundColor: "#0000",
                  fontSize: "11px",
                }}
              />
            </div> */}
        </div>
      ) : (
        <div className="row">
          <div className="col-1" style={{ width: "250px" }}>
            <label
              style={{
                fontSize: "12px",
                marginBottom: "5px",
                display: "block",
              }}
            >
              Profesional Area:
            </label>
            <select
              style={{ fontSize: "11px" }}
              name="areaProfesional"
              className="form-select"
              value={areaProfesional}
              onChange={(e) => setAreaProfesional(e.target.value)}
            >
              <option value="Area Profesional">
                Specific Profesional Area
              </option>
              <option value="Software Development">Software Development</option>
              <option value="Web Development">Web Development</option>
              <option value="Mobile App Development">
                Mobile App Development
              </option>
              <option value="System Architecture">System Architecture</option>
              <option value="Network Administration">
                Network Administration
              </option>
              <option value="Cybersecurity">Cybersecurity</option>
              <option value="Cloud Computing">Cloud Computing</option>
              <option value="DevOps">DevOps</option>
              <option value="Database Management">Database Management</option>
              <option value="Data Science">Data Science</option>
              <option value="Artificial Intelligence">
                Artificial Intelligence
              </option>
              <option value="Machine Learning">Machine Learning</option>
              <option value="Big Data">Big Data</option>
              <option value="IT Support">IT Support</option>
              <option value="IT Consulting">IT Consulting</option>
              <option value="Project Management">Project Management</option>
              <option value="Quality Assurance">Quality Assurance</option>
              <option value="UI/UX Design">UI/UX Design</option>
              <option value="Game Development">Game Development</option>
              <option value="IT Security">IT Security</option>
              <option value="Information Systems Management">
                Information Systems Management
              </option>
              <option value="Systems Integration">Systems Integration</option>
              <option value="IT Governance">IT Governance</option>
              <option value="Enterprise Architecture">
                Enterprise Architecture
              </option>
              <option value="E-commerce">E-commerce</option>
              <option value="Blockchain">Blockchain</option>
              <option value="Virtualization">Virtualization</option>
              <option value="IoT (Internet of Things)">
                IoT (Internet of Things)
              </option>
              <option value="Augmented Reality">Augmented Reality</option>
              <option value="Virtual Reality">Virtual Reality</option>
              <option value="IT Auditing">IT Auditing</option>
              <option value="Business Intelligence">
                Business Intelligence
              </option>
              <option value="Information Technology Infrastructure Library (ITIL)">
                Information Technology Infrastructure Library (ITIL)
              </option>
              <option value="Customer Relationship Management (CRM)">
                Customer Relationship Management (CRM)
              </option>
              <option value="Enterprise Resource Planning (ERP)">
                Enterprise Resource Planning (ERP)
              </option>
              <option value="Computer Forensics">Computer Forensics</option>
              <option value="Health Information Technology">
                Health Information Technology
              </option>
              <option value="Technical Writing">Technical Writing</option>
              <option value="Telecommunications">Telecommunications</option>
              <option value="IT Training">IT Training</option>
            </select>
          </div>

          <div className="col-2" style={{ width: "250px" }}>
            <label
              style={{
                fontSize: "12px",
                marginBottom: "5px",
                display: "block",
              }}
            >
              Verbal Form:
            </label>
            <select
              style={{ fontSize: "11px" }}
              name="verbalForm"
              className="form-select"
              value={verbalForm}
              onChange={(e) => setVerbalForm(e.target.value)}
            >
              <option value="Verbal form">Verbal form</option>
              <option value="Infinitive">Infinitive</option>
              <option value="Present Simple">Present Simple</option>
              <option value="Present Continuous">Present Continuous</option>
              <option value="Past Simple">Past Simple</option>
              <option value="Past Continuous">Past Continuous</option>
              <option value="Present Perfect">Present Perfect</option>
              <option value="Past Perfect">Past Perfect</option>
              <option value="Future Simple">Future Simple</option>
              <option value="Future Continuous">Future Continuous</option>
              <option value="Future Perfect">Future Perfect</option>
              <option value="Conditional">Conditional</option>
              <option value="Subjunctive">Subjunctive</option>
              <option value="Imperative">Imperative</option>
              <option value="Gerund">Gerund</option>
              <option value="Participle">Participle</option>
              <option value="Modal verbs">Modal verbs</option>
              <option value="Phrasal verbs">Phrasal verbs</option>
              <option value="Irregular verbs">Irregular verbs</option>
            </select>
          </div>

          <div className="col-3" style={{ width: "250px" }}>
            <label
              style={{
                fontSize: "12px",
                marginBottom: "5px",
                display: "block",
              }}
            >
              Verb:
            </label>
            <select
              style={{ fontSize: "11px" }}
              name="subjectInput"
              className="form-select"
              value={subjectInput}
              onChange={(e) => setSubjectInput(e.target.value)}
            >
              <option value="verb">Choose the verb</option>
              <option value="to develop">to develop</option>
              <option value="to code">to code</option>
              <option value="to program">to program</option>
              <option value="to debug">to debug</option>
              <option value="to compile">to compile</option>
              <option value="to execute">to execute</option>
              <option value="to deploy">to deploy</option>
              <option value="to test">to test</option>
              <option value="to automate">to automate</option>
              <option value="to configure">to configure</option>
              <option value="to integrate">to integrate</option>
              <option value="to design">to design</option>
              <option value="to architect">to architect</option>
              <option value="to secure">to secure</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to optimize">to optimize</option>
              <option value="to implement">to implement</option>
              <option value="to analyze">to analyze</option>
              <option value="to monitor">to monitor</option>
              <option value="to manage">to manage</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to upgrade">to upgrade</option>
              <option value="to migrate">to migrate</option>
              <option value="to patch">to patch</option>
              <option value="to troubleshoot">to troubleshoot</option>
              <option value="to virtualize">to virtualize</option>
              <option value="to streamline">to streamline</option>
              <option value="to benchmark">to benchmark</option>
              <option value="to document">to document</option>
              <option value="to install">to install</option>
              <option value="to uninstall">to uninstall</option>
              <option value="to scan">to scan</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to query">to query</option>
              <option value="to index">to index</option>
              <option value="to retrieve">to retrieve</option>
              <option value="to store">to store</option>
              <option value="to access">to access</option>
              <option value="to sync">to sync</option>
              <option value="to share">to share</option>
              <option value="to upload">to upload</option>
              <option value="to download">to download</option>
              <option value="to stream">to stream</option>
              <option value="to host">to host</option>
              <option value="to mirror">to mirror</option>
              <option value="to replicate">to replicate</option>
              <option value="to scale">to scale</option>
              <option value="to load">to load</option>
              <option value="to balance">to balance</option>
              <option value="to cache">to cache</option>
              <option value="to validate">to validate</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to parse">to parse</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to initialize">to initialize</option>
              <option value="to instantiate">to instantiate</option>
              <option value="to render">to render</option>
              <option value="to animate">to animate</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to build">to build</option>
              <option value="to generate">to generate</option>
              <option value="to rollback">to rollback</option>
              <option value="to refactor">to refactor</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to validate">to validate</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to parse">to parse</option>
              <option value="to compile">to compile</option>
              <option value="to execute">to execute</option>
              <option value="to commit">to commit</option>
              <option value="to merge">to merge</option>
              <option value="to fork">to fork</option>
              <option value="to branch">to branch</option>
              <option value="to clone">to clone</option>
              <option value="to push">to push</option>
              <option value="to pull">to pull</option>
              <option value="to fetch">to fetch</option>
              <option value="to rollback">to rollback</option>
              <option value="to rebase">to rebase</option>
              <option value="to diff">to diff</option>
              <option value="to log">to log</option>
              <option value="to annotate">to annotate</option>
              <option value="to blame">to blame</option>
              <option value="to cherry-pick">to cherry-pick</option>
              <option value="to stash">to stash</option>
              <option value="to pop">to pop</option>
              <option value="to apply">to apply</option>
              <option value="to drop">to drop</option>
              <option value="to clean">to clean</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to bundle">to bundle</option>
              <option value="to unbundle">to unbundle</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to join">to join</option>
              <option value="to union">to union</option>
              <option value="to intersect">to intersect</option>
              <option value="to except">to except</option>
              <option value="to select">to select</option>
              <option value="to project">to project</option>
              <option value="to group">to group</option>
              <option value="to pivot">to pivot</option>
              <option value="to unpivot">to unpivot</option>
              <option value="to rank">to rank</option>
              <option value="to dense-rank">to dense-rank</option>
              <option value="to row-number">to row-number</option>
              <option value="to cume-dist">to cume-dist</option>
              <option value="to percent-rank">to percent-rank</option>
              <option value="to ntile">to ntile</option>
              <option value="to lag">to lag</option>
              <option value="to lead">to lead</option>
              <option value="to first-value">to first-value</option>
              <option value="to last-value">to last-value</option>
              <option value="to nth-value">to nth-value</option>
              <option value="to generate">to generate</option>
              <option value="to summarize">to summarize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to compute">to compute</option>
              <option value="to calculate">to calculate</option>
              <option value="to evaluate">to evaluate</option>
              <option value="to estimate">to estimate</option>
              <option value="to approximate">to approximate</option>
              <option value="to predict">to predict</option>
              <option value="to forecast">to forecast</option>
              <option value="to model">to model</option>
              <option value="to train">to train</option>
              <option value="to test">to test</option>
              <option value="to validate">to validate</option>
              <option value="to tune">to tune</option>
              <option value="to optimize">to optimize</option>
              <option value="to regularize">to regularize</option>
              <option value="to dropout">to dropout</option>
              <option value="to batch">to batch</option>
              <option value="to normalize">to normalize</option>
              <option value="to standardize">to standardize</option>
              <option value="to transform">to transform</option>
              <option value="to scale">to scale</option>
              <option value="to resample">to resample</option>
              <option value="to impute">to impute</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to embed">to embed</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to stem">to stem</option>
              <option value="to lemmatize">to lemmatize</option>
              <option value="to annotate">to annotate</option>
              <option value="to label">to label</option>
              <option value="to tag">to tag</option>
              <option value="to classify">to classify</option>
              <option value="to cluster">to cluster</option>
              <option value="to segment">to segment</option>
              <option value="to detect">to detect</option>
              <option value="to recognize">to recognize</option>
              <option value="to identify">to identify</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to obfuscate">to obfuscate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to balance">to balance</option>
              <option value="to replicate">to replicate</option>
              <option value="to sync">to sync</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to parse">to parse</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to validate">to validate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to transform">to transform</option>
              <option value="to map">to map</option>
              <option value="to reduce">to reduce</option>
              <option value="to fold">to fold</option>
              <option value="to filter">to filter</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to buffer">to buffer</option>
              <option value="to queue">to queue</option>
              <option value="to dequeue">to dequeue</option>
              <option value="to poll">to poll</option>
              <option value="to stream">to stream</option>
              <option value="to batch">to batch</option>
              <option value="to log">to log</option>
              <option value="to audit">to audit</option>
              <option value="to track">to track</option>
              <option value="to profile">to profile</option>
              <option value="to analyze">to analyze</option>
              <option value="to visualize">to visualize</option>
              <option value="to graph">to graph</option>
              <option value="to chart">to chart</option>
              <option value="to diagram">to diagram</option>
              <option value="to model">to model</option>
              <option value="to simulate">to simulate</option>
              <option value="to emulate">to emulate</option>
              <option value="to test">to test</option>
              <option value="to monitor">to monitor</option>
              <option value="to alert">to alert</option>
              <option value="to notify">to notify</option>
              <option value="to report">to report</option>
              <option value="to debug">to debug</option>
              <option value="to trace">to trace</option>
              <option value="to diagnose">to diagnose</option>
              <option value="to troubleshoot">to troubleshoot</option>
              <option value="to fix">to fix</option>
              <option value="to repair">to repair</option>
              <option value="to resolve">to resolve</option>
              <option value="to escalate">to escalate</option>
              <option value="to automate">to automate</option>
              <option value="to script">to script</option>
              <option value="to schedule">to schedule</option>
              <option value="to orchestrate">to orchestrate</option>
              <option value="to provision">to provision</option>
              <option value="to manage">to manage</option>
              <option value="to administer">to administer</option>
              <option value="to operate">to operate</option>
              <option value="to maintain">to maintain</option>
              <option value="to support">to support</option>
              <option value="to deploy">to deploy</option>
              <option value="to release">to release</option>
              <option value="to version">to version</option>
              <option value="to upgrade">to upgrade</option>
              <option value="to patch">to patch</option>
              <option value="to hotfix">to hotfix</option>
              <option value="to rollback">to rollback</option>
              <option value="to clone">to clone</option>
              <option value="to fork">to fork</option>
              <option value="to merge">to merge</option>
              <option value="to commit">to commit</option>
              <option value="to push">to push</option>
              <option value="to pull">to pull</option>
              <option value="to fetch">to fetch</option>
              <option value="to branch">to branch</option>
              <option value="to tag">to tag</option>
              <option value="to stage">to stage</option>
              <option value="to unstage">to unstage</option>
              <option value="to reset">to reset</option>
              <option value="to rebase">to rebase</option>
              <option value="to diff">to diff</option>
              <option value="to log">to log</option>
              <option value="to annotate">to annotate</option>
              <option value="to blame">to blame</option>
              <option value="to cherry-pick">to cherry-pick</option>
              <option value="to stash">to stash</option>
              <option value="to pop">to pop</option>
              <option value="to apply">to apply</option>
              <option value="to drop">to drop</option>
              <option value="to clean">to clean</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to bundle">to bundle</option>
              <option value="to unbundle">to unbundle</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to join">to join</option>
              <option value="to union">to union</option>
              <option value="to intersect">to intersect</option>
              <option value="to except">to except</option>
              <option value="to select">to select</option>
              <option value="to project">to project</option>
              <option value="to group">to group</option>
              <option value="to pivot">to pivot</option>
              <option value="to unpivot">to unpivot</option>
              <option value="to rank">to rank</option>
              <option value="to dense-rank">to dense-rank</option>
              <option value="to row-number">to row-number</option>
              <option value="to cume-dist">to cume-dist</option>
              <option value="to percent-rank">to percent-rank</option>
              <option value="to ntile">to ntile</option>
              <option value="to lag">to lag</option>
              <option value="to lead">to lead</option>
              <option value="to first-value">to first-value</option>
              <option value="to last-value">to last-value</option>
              <option value="to nth-value">to nth-value</option>
              <option value="to generate">to generate</option>
              <option value="to summarize">to summarize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to compute">to compute</option>
              <option value="to calculate">to calculate</option>
              <option value="to evaluate">to evaluate</option>
              <option value="to estimate">to estimate</option>
              <option value="to approximate">to approximate</option>
              <option value="to predict">to predict</option>
              <option value="to forecast">to forecast</option>
              <option value="to model">to model</option>
              <option value="to train">to train</option>
              <option value="to test">to test</option>
              <option value="to validate">to validate</option>
              <option value="to tune">to tune</option>
              <option value="to optimize">to optimize</option>
              <option value="to regularize">to regularize</option>
              <option value="to dropout">to dropout</option>
              <option value="to batch">to batch</option>
              <option value="to normalize">to normalize</option>
              <option value="to standardize">to standardize</option>
              <option value="to transform">to transform</option>
              <option value="to scale">to scale</option>
              <option value="to resample">to resample</option>
              <option value="to impute">to impute</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to embed">to embed</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to stem">to stem</option>
              <option value="to lemmatize">to lemmatize</option>
              <option value="to annotate">to annotate</option>
              <option value="to label">to label</option>
              <option value="to tag">to tag</option>
              <option value="to classify">to classify</option>
              <option value="to cluster">to cluster</option>
              <option value="to segment">to segment</option>
              <option value="to detect">to detect</option>
              <option value="to recognize">to recognize</option>
              <option value="to identify">to identify</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to obfuscate">to obfuscate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to balance">to balance</option>
              <option value="to replicate">to replicate</option>
              <option value="to sync">to sync</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to parse">to parse</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to validate">to validate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to transform">to transform</option>
              <option value="to map">to map</option>
              <option value="to reduce">to reduce</option>
              <option value="to fold">to fold</option>
              <option value="to filter">to filter</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to buffer">to buffer</option>
              <option value="to queue">to queue</option>
              <option value="to dequeue">to dequeue</option>
              <option value="to poll">to poll</option>
              <option value="to stream">to stream</option>
              <option value="to batch">to batch</option>
              <option value="to log">to log</option>
              <option value="to audit">to audit</option>
              <option value="to track">to track</option>
              <option value="to profile">to profile</option>
              <option value="to analyze">to analyze</option>
              <option value="to visualize">to visualize</option>
              <option value="to graph">to graph</option>
              <option value="to chart">to chart</option>
              <option value="to diagram">to diagram</option>
              <option value="to model">to model</option>
              <option value="to simulate">to simulate</option>
              <option value="to emulate">to emulate</option>
              <option value="to test">to test</option>
              <option value="to monitor">to monitor</option>
              <option value="to alert">to alert</option>
              <option value="to notify">to notify</option>
              <option value="to report">to report</option>
              <option value="to debug">to debug</option>
              <option value="to trace">to trace</option>
              <option value="to diagnose">to diagnose</option>
              <option value="to troubleshoot">to troubleshoot</option>
              <option value="to fix">to fix</option>
              <option value="to repair">to repair</option>
              <option value="to resolve">to resolve</option>
              <option value="to escalate">to escalate</option>
              <option value="to automate">to automate</option>
              <option value="to script">to script</option>
              <option value="to schedule">to schedule</option>
              <option value="to orchestrate">to orchestrate</option>
              <option value="to provision">to provision</option>
              <option value="to manage">to manage</option>
              <option value="to administer">to administer</option>
              <option value="to operate">to operate</option>
              <option value="to maintain">to maintain</option>
              <option value="to support">to support</option>
              <option value="to deploy">to deploy</option>
              <option value="to release">to release</option>
              <option value="to version">to version</option>
              <option value="to upgrade">to upgrade</option>
              <option value="to patch">to patch</option>
              <option value="to hotfix">to hotfix</option>
              <option value="to rollback">to rollback</option>
              <option value="to clone">to clone</option>
              <option value="to fork">to fork</option>
              <option value="to merge">to merge</option>
              <option value="to commit">to commit</option>
              <option value="to push">to push</option>
              <option value="to pull">to pull</option>
              <option value="to fetch">to fetch</option>
              <option value="to branch">to branch</option>
              <option value="to tag">to tag</option>
              <option value="to stage">to stage</option>
              <option value="to unstage">to unstage</option>
              <option value="to reset">to reset</option>
              <option value="to rebase">to rebase</option>
              <option value="to diff">to diff</option>
              <option value="to log">to log</option>
              <option value="to annotate">to annotate</option>
              <option value="to blame">to blame</option>
              <option value="to cherry-pick">to cherry-pick</option>
              <option value="to stash">to stash</option>
              <option value="to pop">to pop</option>
              <option value="to apply">to apply</option>
              <option value="to drop">to drop</option>
              <option value="to clean">to clean</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to bundle">to bundle</option>
              <option value="to unbundle">to unbundle</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to join">to join</option>
              <option value="to union">to union</option>
              <option value="to intersect">to intersect</option>
              <option value="to except">to except</option>
              <option value="to select">to select</option>
              <option value="to project">to project</option>
              <option value="to group">to group</option>
              <option value="to pivot">to pivot</option>
              <option value="to unpivot">to unpivot</option>
              <option value="to rank">to rank</option>
              <option value="to dense-rank">to dense-rank</option>
              <option value="to row-number">to row-number</option>
              <option value="to cume-dist">to cume-dist</option>
              <option value="to percent-rank">to percent-rank</option>
              <option value="to ntile">to ntile</option>
              <option value="to lag">to lag</option>
              <option value="to lead">to lead</option>
              <option value="to first-value">to first-value</option>
              <option value="to last-value">to last-value</option>
              <option value="to nth-value">to nth-value</option>
              <option value="to generate">to generate</option>
              <option value="to summarize">to summarize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to compute">to compute</option>
              <option value="to calculate">to calculate</option>
              <option value="to evaluate">to evaluate</option>
              <option value="to estimate">to estimate</option>
              <option value="to approximate">to approximate</option>
              <option value="to predict">to predict</option>
              <option value="to forecast">to forecast</option>
              <option value="to model">to model</option>
              <option value="to train">to train</option>
              <option value="to test">to test</option>
              <option value="to validate">to validate</option>
              <option value="to tune">to tune</option>
              <option value="to optimize">to optimize</option>
              <option value="to regularize">to regularize</option>
              <option value="to dropout">to dropout</option>
              <option value="to batch">to batch</option>
              <option value="to normalize">to normalize</option>
              <option value="to standardize">to standardize</option>
              <option value="to transform">to transform</option>
              <option value="to scale">to scale</option>
              <option value="to resample">to resample</option>
              <option value="to impute">to impute</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to embed">to embed</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to stem">to stem</option>
              <option value="to lemmatize">to lemmatize</option>
              <option value="to annotate">to annotate</option>
              <option value="to label">to label</option>
              <option value="to tag">to tag</option>
              <option value="to classify">to classify</option>
              <option value="to cluster">to cluster</option>
              <option value="to segment">to segment</option>
              <option value="to detect">to detect</option>
              <option value="to recognize">to recognize</option>
              <option value="to identify">to identify</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to obfuscate">to obfuscate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to balance">to balance</option>
              <option value="to replicate">to replicate</option>
              <option value="to sync">to sync</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to parse">to parse</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to validate">to validate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to transform">to transform</option>
              <option value="to map">to map</option>
              <option value="to reduce">to reduce</option>
              <option value="to fold">to fold</option>
              <option value="to filter">to filter</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to buffer">to buffer</option>
              <option value="to queue">to queue</option>
              <option value="to dequeue">to dequeue</option>
              <option value="to poll">to poll</option>
              <option value="to stream">to stream</option>
              <option value="to batch">to batch</option>
              <option value="to log">to log</option>
              <option value="to audit">to audit</option>
              <option value="to track">to track</option>
              <option value="to profile">to profile</option>
              <option value="to analyze">to analyze</option>
              <option value="to visualize">to visualize</option>
              <option value="to graph">to graph</option>
              <option value="to chart">to chart</option>
              <option value="to diagram">to diagram</option>
              <option value="to model">to model</option>
              <option value="to simulate">to simulate</option>
              <option value="to emulate">to emulate</option>
              <option value="to test">to test</option>
              <option value="to monitor">to monitor</option>
              <option value="to alert">to alert</option>
              <option value="to notify">to notify</option>
              <option value="to report">to report</option>
              <option value="to debug">to debug</option>
              <option value="to trace">to trace</option>
              <option value="to diagnose">to diagnose</option>
              <option value="to troubleshoot">to troubleshoot</option>
              <option value="to fix">to fix</option>
              <option value="to repair">to repair</option>
              <option value="to resolve">to resolve</option>
              <option value="to escalate">to escalate</option>
              <option value="to automate">to automate</option>
              <option value="to script">to script</option>
              <option value="to schedule">to schedule</option>
              <option value="to orchestrate">to orchestrate</option>
              <option value="to provision">to provision</option>
              <option value="to manage">to manage</option>
              <option value="to administer">to administer</option>
              <option value="to operate">to operate</option>
              <option value="to maintain">to maintain</option>
              <option value="to support">to support</option>
              <option value="to deploy">to deploy</option>
              <option value="to release">to release</option>
              <option value="to version">to version</option>
              <option value="to upgrade">to upgrade</option>
              <option value="to patch">to patch</option>
              <option value="to hotfix">to hotfix</option>
              <option value="to rollback">to rollback</option>
              <option value="to clone">to clone</option>
              <option value="to fork">to fork</option>
              <option value="to merge">to merge</option>
              <option value="to commit">to commit</option>
              <option value="to push">to push</option>
              <option value="to pull">to pull</option>
              <option value="to fetch">to fetch</option>
              <option value="to branch">to branch</option>
              <option value="to tag">to tag</option>
              <option value="to stage">to stage</option>
              <option value="to unstage">to unstage</option>
              <option value="to reset">to reset</option>
              <option value="to rebase">to rebase</option>
              <option value="to diff">to diff</option>
              <option value="to log">to log</option>
              <option value="to annotate">to annotate</option>
              <option value="to blame">to blame</option>
              <option value="to cherry-pick">to cherry-pick</option>
              <option value="to stash">to stash</option>
              <option value="to pop">to pop</option>
              <option value="to apply">to apply</option>
              <option value="to drop">to drop</option>
              <option value="to clean">to clean</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to bundle">to bundle</option>
              <option value="to unbundle">to unbundle</option>
              <option value="to filter">to filter</option>
              <option value="to sort">to sort</option>
              <option value="to join">to join</option>
              <option value="to union">to union</option>
              <option value="to intersect">to intersect</option>
              <option value="to except">to except</option>
              <option value="to select">to select</option>
              <option value="to project">to project</option>
              <option value="to group">to group</option>
              <option value="to pivot">to pivot</option>
              <option value="to unpivot">to unpivot</option>
              <option value="to rank">to rank</option>
              <option value="to dense-rank">to dense-rank</option>
              <option value="to row-number">to row-number</option>
              <option value="to cume-dist">to cume-dist</option>
              <option value="to percent-rank">to percent-rank</option>
              <option value="to ntile">to ntile</option>
              <option value="to lag">to lag</option>
              <option value="to lead">to lead</option>
              <option value="to first-value">to first-value</option>
              <option value="to last-value">to last-value</option>
              <option value="to nth-value">to nth-value</option>
              <option value="to generate">to generate</option>
              <option value="to summarize">to summarize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to compute">to compute</option>
              <option value="to calculate">to calculate</option>
              <option value="to evaluate">to evaluate</option>
              <option value="to estimate">to estimate</option>
              <option value="to approximate">to approximate</option>
              <option value="to predict">to predict</option>
              <option value="to forecast">to forecast</option>
              <option value="to model">to model</option>
              <option value="to train">to train</option>
              <option value="to test">to test</option>
              <option value="to validate">to validate</option>
              <option value="to tune">to tune</option>
              <option value="to optimize">to optimize</option>
              <option value="to regularize">to regularize</option>
              <option value="to dropout">to dropout</option>
              <option value="to batch">to batch</option>
              <option value="to normalize">to normalize</option>
              <option value="to standardize">to standardize</option>
              <option value="to transform">to transform</option>
              <option value="to scale">to scale</option>
              <option value="to resample">to resample</option>
              <option value="to impute">to impute</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to embed">to embed</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to stem">to stem</option>
              <option value="to lemmatize">to lemmatize</option>
              <option value="to annotate">to annotate</option>
              <option value="to label">to label</option>
              <option value="to tag">to tag</option>
              <option value="to classify">to classify</option>
              <option value="to cluster">to cluster</option>
              <option value="to segment">to segment</option>
              <option value="to detect">to detect</option>
              <option value="to recognize">to recognize</option>
              <option value="to identify">to identify</option>
              <option value="to authenticate">to authenticate</option>
              <option value="to authorize">to authorize</option>
              <option value="to encrypt">to encrypt</option>
              <option value="to decrypt">to decrypt</option>
              <option value="to obfuscate">to obfuscate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to aggregate">to aggregate</option>
              <option value="to partition">to partition</option>
              <option value="to shard">to shard</option>
              <option value="to balance">to balance</option>
              <option value="to replicate">to replicate</option>
              <option value="to sync">to sync</option>
              <option value="to backup">to backup</option>
              <option value="to restore">to restore</option>
              <option value="to archive">to archive</option>
              <option value="to extract">to extract</option>
              <option value="to compress">to compress</option>
              <option value="to decompress">to decompress</option>
              <option value="to encode">to encode</option>
              <option value="to decode">to decode</option>
              <option value="to serialize">to serialize</option>
              <option value="to deserialize">to deserialize</option>
              <option value="to parse">to parse</option>
              <option value="to tokenize">to tokenize</option>
              <option value="to validate">to validate</option>
              <option value="to sanitize">to sanitize</option>
              <option value="to normalize">to normalize</option>
              <option value="to transform">to transform</option>
              <option value="to map">to map</option>
              <option value="to reduce">to reduce</option>
              <option value="to fold">to fold</option>
              <option value="to filter">to filter</option>
            </select>
          </div>

          {/* <div className="col-3" style={{ width: "100px" }}>
              <label
                style={{
                  fontSize: "12px",
                  display: "block",
                }}
              >
                Verb:
              </label>
              <input
                className="rounded prompt"
                type="text"
                name="subjectInput"
                value={subjectInput}
                onChange={(e) => setSubjectInput(e.target.value)}
                style={{
                  backgroundColor: "#0000",
                  fontSize: "11px",
                }}
              />
            </div> */}
        </div>
      )}

      <div className="row">
        <div className="d-flex justify-content-center">
          <button className="prompt-add-btn" onClick={onClickAdd}>
            {loading && <Spinner />}
            {ADD}
            <i className="bi bi-magic" style={{ marginLeft: "8px" }}></i>
          </button>
        </div>
      </div>

      <div className="mt-4">
        {responseText && (
          <div className="card">
            <div className="card-body">
              {/* <h5 className="card-title">Generated Examples</h5> */}
              <div className="examples-list">{renderExamples()}</div>
            </div>
          </div>
        )}
        {error && <p className="text-danger mt-3">{error}</p>}
      </div>
    </div>
  );
}

function Vocabulary() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div className="p-2 overflow-auto">
      <div className="d-flex flex-column mt-0">
        <Words />
      </div>
    </div>
  );
}

function Words(props) {
  const [verbalForm, setVerbalForm] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [responseText, setResponseText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGenerate = async () => {
    setLoading(true);
    const timestamp = Date.now() - 5000;
    const promptText = `Please correct my phrase: ${subjectInput}`;
    const url =
      "https://xqpa24frhml6em7ceyqy5zxzvm0evqdd.lambda-url.us-east-2.on.aws/generate";

    try {
      const response = await axios.get(url, {
        params: {
          prompt: promptText,
        },
      });

      setResponseText(response.data.response);
      setError("");

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to generate text. Please try again."); // Set error message for UI
      setResponseText(""); // Clear previous response on error

      setLoading(false);
    }
  };

  const onClickAdd = (e) => {
    e.preventDefault();
    handleGenerate();
    logEvent(analytics, "cors access", {
      timestamp: Date.now(),
    });
  };

  useEffect(() => {
    const meta = document.createElement("meta");
    meta.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    );
    meta.setAttribute("name", "viewport");
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const renderExamples = () => {
    return responseText.split("\n").map((example, index) => {
      // Replace **text** with <span class="highlight">text</span>
      const formattedExample = example.replace(
        /\*\*(.*?)\*\*/g,
        '<span class="highlight">$1</span>'
      );

      // Use dangerouslySetInnerHTML to render HTML inside React
      return (
        <p
          key={index}
          className="example-item"
          dangerouslySetInnerHTML={{ __html: formattedExample }}
        />
      );
    });
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className="row justify-content-center">
        <div style={{ width: "200px" }}>
          <label
            style={{
              fontSize: "12px",

              display: "block",
            }}
          >
            Expression:
          </label>
          <input
            className="rounded prompt"
            type="text"
            name="subjectInput"
            value={subjectInput}
            onChange={(e) => setSubjectInput(e.target.value)}
            style={{
              backgroundColor: "#0000",
              fontSize: "11px",
              width: window.innerWidth < 1600 ? "200px" : "200px",
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="d-flex justify-content-center">
          <button className="prompt-add-btn" onClick={onClickAdd}>
            {loading && <Spinner />}
            My expression is correct?
            <i className="bi bi-magic" style={{ marginLeft: "8px" }}></i>
          </button>
        </div>
      </div>

      <div className="mt-4">
        {responseText && (
          <div className="cardVocabulary">
            <div className="cardVocabulary-body">
              {/* <h5 className="card-title">Generated Examples</h5> */}
              <div className="examples-list">{renderExamples()}</div>
            </div>
          </div>
        )}
        {error && <p className="text-danger mt-3">{error}</p>}
      </div>
    </div>
  );
}
