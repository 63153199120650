// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAQnI9y5QkiRe-CUvwFZBbLaBj1Y7UUM2c",
  authDomain: "powerful-english.firebaseapp.com",
  projectId: "powerful-english",
  storageBucket: "powerful-english.appspot.com",
  messagingSenderId: "85649927103",
  appId: "1:85649927103:web:61da14a5eff3ebfb6b8f0a",
  measurementId: "G-1Y1KWSR19N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
