import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import MonacoEditor from "react-monaco-editor";

export default function ExerciseCommentCode() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(`
    function hello() {
      // This is a comment
      console.log('Hello, world!');
    }
  `);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  const editorDidMount = (editor, monaco) => {
    console.log("Editor mounted");

    editor.updateOptions({
      tabSize: 2,
      insertSpaces: true,
      trimAutoWhitespace: true,
      wordWrap: "on",
      scrollBeyondLastLine: false,
      fontFamily: "Fira Code",
      fontSize: 14,
      lineNumbers: "on",
      theme: "vs-dark",
      readOnly: false,
      glyphMargin: true,
    });

    monaco.editor.defineTheme("customTheme", {
      base: "vs-dark",
      inherit: true,
      rules: [
        { token: "comment", foreground: "00FF00" }, // Green color for comments
      ],
      colors: {},
    });

    monaco.editor.setTheme("customTheme");
  };

  const handleGenerate = async () => {
    setLoading(true);
    const promptText = `Could you please review the code and provide comments that are essential for documentation purposes. My code: "${code}"`;
    const url =
      "https://xqpa24frhml6em7ceyqy5zxzvm0evqdd.lambda-url.us-east-2.on.aws/generate";

    try {
      const response = await axios.get(url, {
        params: {
          prompt: promptText,
        },
      });

      setCode(response.data.response);
      setError("");

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to generate text. Please try again."); // Set error message for UI

      setLoading(false);
    }
  };

  const onClickAdd = (e) => {
    e.preventDefault();
    handleGenerate();
  };

  return (
    <div className="mt-4">
      <h5>Powerful Interactive Development Environment</h5>
      <p>
        Learn how to comment code correctly in English by inputting a code
        snippet.
      </p>
      <strong>This is essential for code review and understanding</strong>
      <MonacoEditor
        width={isMobileDevice() ? "370px" : "800px"}
        height={isMobileDevice() ? "390px" : "600px"}
        language="javascript"
        theme="customTheme"
        value={code}
        onChange={handleCodeChange}
        editorDidMount={editorDidMount}
      />
      <div className="d-flex justify-content-center">
        <button className="prompt-add-btn" onClick={onClickAdd}>
          {loading && <Spinner />}I want to learn with my code
          <i className="bi bi-magic" style={{ marginLeft: "8px" }}></i>
        </button>
      </div>
    </div>
  );
}
